import { useState } from "react"

export function TopHeaderMenu({ navn }: { navn: string }) {
    const [visMeny, setVisMeny] = useState(false)

    return (
        <div className="headerContainer">
            <div className="headerContent">
                <div className="backButton">
                    <a className="backButtonText" href="/">
                        Forside
                    </a>
                </div>
                <div className="nameAndMenu">
                    <p>
                        Innlogget som: <strong>{navn}</strong>
                    </p>
                    <div
                        className="burgerMenu"
                        onClick={() => setVisMeny(!visMeny)}
                    >
                        <span className="burgerLine" />
                        <span className="burgerLine" />
                        <span className="burgerLine" />
                    </div>
                </div>
            </div>
            {visMeny && (
                <div className="menu">
                    <a className="menuElement" href="informasjon">
                        Informasjon
                    </a>
                    <a className="menuElement" href="huskeliste">
                        Huskeliste
                    </a>
                    <a className="menuElement" href="tips">
                        Tips
                    </a>
                </div>
            )}
        </div>
    )
}
