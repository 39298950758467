import React, { useEffect, useState } from "react"
import { Recommendation } from "../types/supabase"
import supabase from "../supabaseconfig"

export default function Tips() {
    const [recommendations, setRecommendations] = useState<Recommendation[]>([])

    useEffect(() => {
        getRecommendations()
    }, [])

    async function getRecommendations(): Promise<void> {
        let { data, error } = await supabase()
            .from("recommendations")
            .select("*")

        if (error) {
            console.error("Error fetching recommendations:", error)
            return
        }

        if (data) {
            setRecommendations(data)
        }
    }

    return (
        <div>
            <div className="page">
                <h1 className="topHeader">TIPS</h1>
            </div>
            <div className="underPage">
                {recommendations.map((it: Recommendation) => (
                    <div key={it.id}>
                        <p>
                            <strong>Navn</strong>
                        </p>
                        <p>{it.name}</p>
                        <p>
                            <strong>Rating</strong>
                        </p>
                        <p>{it.rating}</p>
                        <p>
                            <strong>Beskrivelse</strong>
                        </p>
                        <p>{it.description}</p>
                        <p>
                            <strong>Adresse</strong>
                        </p>
                        <p>{it.address}</p>
                        {it.url && (
                            <a className={"url"} href={it.url}>
                                Nettside
                            </a>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}
