import React from 'react';
import {TopHeaderMenu} from "../TopHeaderMenu";
import {Outlet} from "react-router-dom";

export default function Layout({navn}: {navn: string}) {

    return (
        <div className="front">
            <TopHeaderMenu navn={navn}/>
            <Outlet/>
        </div>
    )
}
